import analysisMapModule from "../analysisMapModule"
import angular from "angular"
import tripEditionModal from "./tripEditionModal.html"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisMapModule.service("tripEditionModal", ($q,
                                               $modal,
                                               autocompleteFactory,
                                               tripEditionService,
                                               loadingService,
                                               violationModal,
                                               $translate,
                                               messagesModal,
                                               remoteExceptionHandler) => {
    return (selection) => {
        let tripNodeIdentifier = selection[0]
        return loadingService(
            tripEditionService.canEditTrip(tripNodeIdentifier).then(() => {
                return tripEditionService.findTrip(tripNodeIdentifier.id).then((tripDTO) => {
                    return $modal.open({
                        template: tripEditionModal,
                        size: "lg",
                        backdrop: "static",
                        controller: ["$scope", ($scope) => {

                            $scope.model = {
                                tripNodeIdentifier: tripNodeIdentifier,
                                vehicle: tripDTO.vehicle,
                                truck: tripDTO.truck,
                                autoSelectVehicle: tripDTO.autoSelectVehicle,
                                observation: tripDTO.observation
                            }

                            $scope.selection = {
                                stops: []
                            }

                            $scope.multipleSelection = true

                            $scope.vehicles = autocompleteFactory.lazyLoader("vehicle")

                            function updatePlatesOnAutocomplete() {
                                $scope.plates = autocompleteFactory.lazyLoader("plate", [], {
                                    "vehicle": $scope.model.vehicle === null ? null : $scope.model.vehicle.id
                                })
                            }

                            updatePlatesOnAutocomplete()

                            $scope.onVehicleChange = () => {
                                $scope.model.truck = null
                                updatePlatesOnAutocomplete()
                            }

                            $scope.onAutoSelectVehicleChange = () => {
                                $scope.model.vehicle = null
                                $scope.model.truck = null
                            }

                            let initSequence = []
                            tripEditionService.getStopsByTripId(tripNodeIdentifier.id).then((stops) => {
                                $scope.selection.stops = stops
                                arrays.each($scope.selection.stops, function (stop) {
                                    initSequence.push(stop.sequenceOnTrip)
                                })
                            })

                            $scope.format = function (tag) {
                                if (tag === null || angular.isUndefined(tag)) {
                                    return ""
                                }
                                const locality = tag.locality
                                const localitySourceId = locality.sourceId
                                if (locality && localitySourceId) {
                                    return $translate.instant("programming.trip.edition.modal.stop") + " "
                                        + (parseInt(tag.sequenceOnTrip) + 1) + " - "
                                        + locality.name + " ( " + localitySourceId + " ) - " + locality.city
                                        + " ( " + $scope.getLogisticType(tag) + " )"
                                }
                                return tag.id
                            }

                            $scope.getLogisticType = (tag) => {
                                const {loadedDeliveryUnits, unloadedDeliveryUnits} = tag
                                const isLoading = loadedDeliveryUnits.length > 0
                                const isUnloading = unloadedDeliveryUnits.length > 0

                                if (isLoading && isUnloading) {
                                    return $translate.instant("programming.trip.edition.modal.stop.loading.unloading")
                                }
                                if (isUnloading) {
                                    return $translate.instant("programming.trip.edition.modal.stop.unloading")
                                }
                                return $translate.instant("programming.trip.edition.modal.stop.loading")
                            }

                            $scope.canMoveDown = (selects, down) => {
                                if (selects === undefined || selects.length === 0 || down === undefined) {
                                    return false
                                }
                                return selects.every(select => arrays.disjoint(select.loadedDeliveryUnits, down.unloadedDeliveryUnits))
                            }

                            $scope.canMoveUp = (selects, up) => {
                                if (selects === undefined || selects.length === 0 || up === undefined) {
                                    return false
                                }
                                return selects.every(select => arrays.disjoint(select.unloadedDeliveryUnits, up.loadedDeliveryUnits))
                            }

                            function doAlterStops() {
                                let stops = angular.copy($scope.selection.stops)
                                let initialSequence = initSequence

                                let sequenceParam = []
                                arrays.each(stops, function (stop) {
                                    sequenceParam.push(stop.sequenceOnTrip)
                                })

                                $scope.model.stopSequences = angular.equals(sequenceParam, initialSequence) ? [] : sequenceParam
                            }

                            $scope.save = () => {
                                doAlterStops()
                                return loadingService(
                                    tripEditionService.editTrip($scope.model)
                                        .then((response) => {
                                            if (response !== "") {
                                                return violationModal(response).then((result) => {
                                                    return tripEditionService.confirmEdition(result.token)
                                                }).then(() => {
                                                    $scope.model.tripNodeIdentifier.version++
                                                    return $q.resolve()
                                                })
                                            }
                                        })
                                        .then(() => {
                                            return messagesModal("dialog.success", [{
                                                keyBundle: "trip.edition.success",
                                                parameters: []
                                            }]).then(() => $scope.$close())
                                        })
                                        .catch((error) => {
                                            if (angular.isUndefined(error) || angular.isUndefined(error.data)) {
                                                return $q.reject(error)
                                            } else if (error.data.type === "br.com.neolog.service.programming.edit.trip.ProgrammingTripEditionException$Type.ERROR") {
                                                return messagesModal("dialog.error", error.data.messages)
                                            } else {
                                                return remoteExceptionHandler()(error)
                                            }
                                        })
                                )

                            }
                            $scope.model.maxLengthObservation = 255
                            $scope.getMaxLengthKeyPressInformation = function () {
                                let keyPressedLength = $scope.model.observation ? $scope.model.observation.length : 0
                                return `${keyPressedLength} / ${$scope.model.maxLengthObservation}`
                            }
                        }]
                    }).result
                })
            }).catch(error => {
                if (angular.isUndefined(error) || angular.isUndefined(error.data)) {
                    return $q.reject(error)
                } else if (error.data.type === "br.com.neolog.service.programming.edit.trip.ProgrammingTripEditionException$Type.ERROR") {
                    messagesModal("dialog.error", error.data.messages)
                } else {
                    return remoteExceptionHandler()(error)
                }
            }))
    }
})
