import allowedOriginsModule from "./allowedOriginsModule"
import tdnSpecification from "../r2/tdnSpecification.json"

allowedOriginsModule.controller("allowedOriginsController", ["$scope", "allowedOriginsService", "remoteExceptionHandler", "loadingService", "messagesModal", ($scope, allowedOriginsService, remoteExceptionHandler, loadingService, messagesModal) => {
    $scope.tdn = tdnSpecification["allowed-origins"]

    $scope.allowedOrigins = []
    $scope.userOriginRestriction = {
        user: {},
        origins: []
    }

    $scope.save = function () {
        loadingService(allowedOriginsService.save($scope.userOriginRestriction))
            .catch(remoteExceptionHandler())
            .then(() => messagesModal("dialog.success", "entity.validator.successMessage"))
    }

    $scope.search = function (patch) {
        loadingService(allowedOriginsService.findUserOriginRestriction(patch))
            .then((response) => {
                $scope.userOriginRestriction.origins = response.result[0].origins
                $scope.userOriginRestriction.user = response.result[0].user
            })
            .then(() => {
                allowedOriginsService.findAllAllowedOrigins().then((origins) => {
                    $scope.allowedOrigins = origins
                })
            })
    }
}])