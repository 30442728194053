import {configParamsModule} from "./configParamsModule";
import {IntegrationService} from "../integration/integrationService";
import {R2IntegrationSysParams} from "../integration/R2IntegrationSysParams";
import {ConfigParamsService} from "./configParamsService";
import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../bower_components/front-end-web-commons";
import {IntegrationValidatorResult} from "./model/integrationValidatorResult";
import {MutableConfigParams} from "./model/mutableConfigParams";
import {ConfigFunctionalities} from "./model/configFunctionalities";
import * as angular from "angular";
import * as jiff from "jiff"
import {RemoteRoutingService} from "./remoteRouting";
import {server} from "../server";

class ConfigParamsController {
    private integrationConfig: R2IntegrationSysParams;

    private dashboardConfiguration: { dashboardSourceId: string; } = {dashboardSourceId: "gcpOverview"};
    private resourceDashboardConfiguration: { dashboardSourceId: string; } = {dashboardSourceId: "gcpResourceOverview"};

    private originalConfigParams: MutableConfigParams;
    private modifiedConfigParams: MutableConfigParams;
    private integrationDataSource: string = "";
    private integrationInterfaceName: string = "";
    private integrationTestResults: IntegrationValidatorResult;
    private configFunctionalities: ConfigFunctionalities;

    constructor(private integrationService: IntegrationService,
                private configParamsService: ConfigParamsService,
                private remoteRoutingService: RemoteRoutingService,
                private loadingService: LoadingService,
                private remoteExceptionHandler: RemoteExceptionHandler,
                private messagesModal: MessagesModal,
                private remoteServerModalService: any
    ) {
        this.loadingService(this.loadIntegration());
        this.loadingService(this.loadSysConfig());
        this.loadingService(this.loadConfigFunctionalities());
    }

    private loadSysConfig() {
        return this.configParamsService.getConfigParams()
            .then(configParams => {
                this.originalConfigParams = configParams;
                this.modifiedConfigParams = angular.copy(configParams);
            }).catch(this.remoteExceptionHandler())
    }

    saveSysConfig() {
        var patch = jiff.diff(this.originalConfigParams, this.modifiedConfigParams, {invertible: false})
        if (patch.length === 0) {
            this.messagesModal("dialog.warning", ["config.params.toll.setting.without.modification"]);
            return;
        }
        return this.configParamsService.updateConfigParams(patch, this.modifiedConfigParams.version)
            .then(() => this.loadingService(this.loadSysConfig())
                .then(() => this.messagesModal("dialog.success", ["config.params.success"])
                )
            );
    }


    private loadIntegration() {
        return this.integrationService.getIntegrationConfig()
            .then(integrationConfig => this.integrationConfig = integrationConfig)
            .catch(this.remoteExceptionHandler())
    }

    saveIntegrationConfig() {
        return this.loadingService(this.integrationService.createOrUpdateConfig(this.integrationConfig)
            .catch(this.remoteExceptionHandler())
            .then(() => {
                this.messagesModal("dialog.success", ["config.params.success"])
                    .then(() => this.loadIntegration() )
            }));
    }

    testConnectivity(url: string) {
        return this.loadingService(this.integrationService.testConnectivity(url))
            .catch(this.remoteExceptionHandler())
            .then((status: boolean) => {
                if (status === true) {
                    this.messagesModal("dialog.success", ["integration.connectivity.ok"]);
                } else {
                    this.messagesModal("dialog.error", ["integration.connectivity.error"]);
                }
            })
    }

    clearServerCache() {
        return this.loadingService(this.configParamsService.clearServerCache())
            .catch(this.remoteExceptionHandler())
    }

    clearIntegrationCache() {
        return this.loadingService(this.configParamsService.clearIntegrationCache())
            .catch(this.remoteExceptionHandler())
    }

    routerServer() {
        return this.remoteServerModalService.open()
    }

    getIntegrationDataSources() {
        return this.configParamsService.getIntegrationDataSources()
    }

    getAvailableInterfaces() {
        return this.configParamsService.getAvailableInterfaces()
    }

    testPublish() {
        return this.loadingService(this.configParamsService.testPublish(this.integrationInterfaceName, this.integrationDataSource))
            .catch(this.remoteExceptionHandler())
            .then(result => this.integrationTestResults = result)
    }

    getDashboardConfiguration() {
        return this.dashboardConfiguration
    }

    getResouceDashboardConfiguration() {
        return this.resourceDashboardConfiguration
    }

    purgeProgrammingDatabase() {
        return this.messagesModal.cancellable("dialog.warning", ["config.params.database.purge.warning.message"])
            .then(() => this.messagesModal("dialog.success", ["config.params.database.purge.started"])
                .then(() => this.loadingService(this.loadSysConfig())
                    .then(() => this.configParamsService.purgeProgrammingDatabase()
                        .catch(this.remoteExceptionHandler())))
            );
    }

    purgeExecutionDatabase() {
        return this.messagesModal.cancellable("dialog.warning", ["config.params.database.purge.warning.message"])
            .then(() => this.messagesModal("dialog.success", ["config.params.database.purge.started"])
                .then(() => this.loadingService(this.loadSysConfig())
                    .then(() => this.configParamsService.purgeExecutionDatabase()
                        .catch(this.remoteExceptionHandler())))
            );
    }

    saveFunctionalitiesConfig() {
        return this.loadingService(this.configParamsService.saveConfigFunctionalities(this.configFunctionalities))
            .catch(this.remoteExceptionHandler())
            .then(() => this.messagesModal("dialog.success", ["functionalities.config.success"]))
            .then(() => this.loadingService(this.loadConfigFunctionalities()))
    }

    private loadConfigFunctionalities() {
        return this.configParamsService.findConfigFunctionalities()
            .then(result => {
                this.configFunctionalities = result;
            }).catch(this.remoteExceptionHandler())
    }
}

configParamsModule.controller("configParamsController", ConfigParamsController)