import {IHttpService, IPromise, IQService} from "angular";
import * as _ from "lodash";
import gatewayServer from "../../gatewayServer";
import {integrationDocumentationModule} from "./integrationDocumentationModule";
import {IntegrationDocumentationParser, WsdlDefinition} from "./integrationDocumentationParser";

export interface WsdlIdentifier {
    service: string
    wsdlName: string
}

export class IntegrationDocumentationService {
    constructor(private $http: IHttpService,
                private integrationDocumentationParser: IntegrationDocumentationParser,
                private $q: IQService) {
    }

    getWsdls(): IPromise<WsdlIdentifier[]> {
        return this.$http.get<WsdlIdentifier[]>(gatewayServer.getServiceUrl("web-services")).then(response => response.data);
    }

    getWsdlContentLink(identifier: WsdlIdentifier) {
        return (gatewayServer.getServiceUrl("web-services")
            + "/" + identifier.service
            + "/" + identifier.wsdlName)
            .replace(/\/+/g, "/");
    }

    getWsdlContent(identifier: WsdlIdentifier): IPromise<string> {
        return this.$http.get<string>(this.getWsdlContentLink(identifier))
            .then(response => response.data)
    }

    parseWsdlDefinition(identifier: WsdlIdentifier, wsdl: string): IPromise<WsdlDefinition> {
        const definition = this.integrationDocumentationParser.parseWsdl(wsdl)
        return this.$q.all(definition.includeTypes.map(include =>
            this.getWsdlContent({...identifier, wsdlName: include})
                .then(wsdl => this.parseWsdlDefinition(identifier, wsdl))))
            .then(includeTypes => _.merge.apply(_, includeTypes.concat([definition])))
    }


}

integrationDocumentationModule.service("integrationDocumentationService", IntegrationDocumentationService)