import {configParamsModule} from "../configParamsModule"
import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons";
import {BiModel, ConfigParamsService} from "../configParamsService";

class NlgMicrostrategyConfiguration {
    model: BiModel = {
        id: null,
        segment: "",
        profile: "",
        company: "",
        product: "",
        quantityOfLicenses: 0,
        allowedUsers: []
    }
    private users: any = [];

    constructor(private loadingService: LoadingService,
                private remoteExceptionHandler: RemoteExceptionHandler,
                private messagesModal: MessagesModal,
                private configParamsService: ConfigParamsService,
                private $state: any) {
        this.loadingService(this.configParamsService.getBiConfigurations())
            .catch(this.remoteExceptionHandler())
            .then((configurations: any) => {
                this.model = configurations;
            });

        this.loadingService(this.configParamsService.getUsers())
            .catch(this.remoteExceptionHandler())
            .then((users: any) => {
                this.users = users;
            });
        this.model.allowedUsers = []
    }

    getBiConfigurations() {
        return this.configParamsService.getBiConfigurations()
    }

    canMoveUser() {
        return this.model.allowedUsers.length < this.model.quantityOfLicenses
    }

    saveBiConfigurations() {
        return this.loadingService(this.configParamsService.saveBiConfigurations(this.model))
            .catch(this.remoteExceptionHandler())
            .then(() => this.messagesModal("dialog.success", ["bi.configurations.saved"]).then(() => this.$state.reload()));
    }

}

export const directive = configParamsModule.directive("nlgMicrostrategyConfiguration", [function () {
    return {
        restrict: "E",
        templateUrl: require("link!./nlgMicrostrategyConfiguration.html"),
        scope: {},
        controllerAs: "ctrl",
        controller: NlgMicrostrategyConfiguration
    }
}])